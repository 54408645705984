@import url("https://fonts.googleapis.com/css2?family=Roboto&family=Rock+Salt&display=swap");
/* font-family: 'Roboto', sans-serif;
font-family: 'Rock Salt', cursive; */

/*** Header ***/
.mainHead {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
  width: 100%;
  background-position: bottom;
  /* background-attachment: fixed; */
  background-image: url("https://spacenews.com/wp-content/uploads/2018/05/24359364107_152b0152ff_k.jpg");
}

/* .mainHead::after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  transform-origin: top left;
  transform: skewY(-23deg);
  background-position: inherit;
  
} */

.headName {
  font-family: "Rock Salt", cursive;
  color: white;
  text-align: center;
  font-size: 5rem;
  letter-spacing: 0.5rem;
  text-shadow: 8px 3px blue;
}
.headLinks {
  border-top: white solid 2px;
  border-bottom: white solid 2px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 8vh;
  background-color: black;
}
.mainPhoto {
  width: 15%;
}
.mainPhoto img {
  border: 2px solid white;
  border-radius: 30px;
  width: 100%;
}
/*** Body ***/
/* About */
.aboutMe {
  padding: 5%;
}

/* Links */
.links {
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-flow: column wrap;
  text-align: center;
}
.linkBox {
  width: 90%;
  display: flex;
  justify-content: space-evenly;
  flex-flow: row wrap;
  border: solid 1px blanchedalmond;
}

.linkItem {
  /* border: 1px blanchedalmond solid; */
  width: 25%;
  margin: 2%;
  padding: 2%;
}
.linkItem:hover {
  cursor: pointer;
}

/* Link src */
.imgIcon {
  width: 25%;
}

/*** Footer ***/
footer {
  margin-top: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 8vh;
  background-color: black;
}
